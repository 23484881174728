.about-intro {
  text-align: center;
  @include type(36px, 1.6);
  background: #FF5D26; //#D2386C; //#0F0F0F;

  color: #fff;
  font-weight: 600;
  .home-columns-content {
    margin: 0 0 80px;
    .wp-block-column {
      @include type(24px, 1.6);
      min-height: 190px;
      position: relative;
      color: #000; //3E003E;
      p {
        @include absolute-center-vertical();
        padding: 0;
        font-weight: 600;
      }
    }
    .wp-block-column:nth-child(2) {
      border-left: 1px solid rgba(#fff, .4);
      border-right: 1px solid rgba(#fff, .4);
      p {
        padding: 0 35px 0 25px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    @include type(24px, 1.6);
    .home-columns-content {
      margin: 0 0 80px;
      .wp-block-column {
        @include type(18px, 1.6);
        min-height: 150px;
      }
      .wp-block-column:nth-child(2) {
        border-left: 1px solid rgba(#fff, .4);
        border-right: 1px solid rgba(#fff, .4);
        p {
          padding: 0 25px 0 20px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    @include type(21px, 1.6);
    .home-columns-content {
      margin: 0 0 80px;
      .wp-block-column {
        @include type(16px, 1.5);
        min-height: 150px;
      }
      .wp-block-column:nth-child(2) {
        p {
          padding: 0 15px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    @include type(18px, 1.6);
    .home-columns-content {
      margin: 0 0 60px;
      .wp-block-column {
        flex-basis: 100% !important;
        min-height: 100px;
        &:nth-child(2) {
          border: none;
        }
          
      }
    }
  }
  
  
}