
@import "mixins";

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  /* many screen reader and browser combinations announce broken words as they would appear visually */
  word-wrap: normal !important;
}

/* must have higher specificity than alternative color schemes inline styles */
.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}

.site .skip-link:focus {
  clip: auto;
  height: auto;
  left: 6px;
  top: 7px;
  width: auto;
  z-index: 100000;
}

body {
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}


// import theme typography
@import 'typography';

@import 'menu-toggle';


@import 'header';
@import 'footer';
@import 'layout';


// import page specific styles
@import 'home';
@import 'about';
@import 'blog';