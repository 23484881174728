.home-hero {
  background-color: #fecf2d;
  position: relative;
  padding: 250px 0 150px;
  @include bg-cover();
  color: #fff;
  &__overlay {
    width: 100%;
  }
  .container {
    position: relative;
    text-align: center;
    max-width: 1080px;
  }
  h2 {
    @include type(64px, 1.25);
    margin: 0 0 20px;
    @include style-heading();
    font-weight: 800;
    color: $color-lime;
  }
  p {
    margin: 0;
    @include type(30px, 1.3);
    font-weight: 600;
  }

  @include media-breakpoint-down(lg) {
    padding: 200px 0 100px;
    h2 {
      @include type(56px, 1.25);
    }
    p {
      margin: 0;
      @include type(24px, 1.3);
    }
  }

  @include media-breakpoint-down(md) {
    padding: 160px 0 80px;
    h2 {
      @include type(44px, 1.4);
    }
    p {
      @include type(21px, 1.3);
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 140px 0 80px;
    h2 {
      @include type(30px, 1.4);
    }
    p {
      @include type(18px, 1.5);
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 120px 0 50px;
    h2 {
      @include type(24px, 1.4);
    }
    p {
      @include type(16px, 1.5);
    }
  }
}



.work-with-you {
  background: $color-teal;
  .heading2 {
    @include type(48px, 1.1);
    margin: 0 0 50px;
  }
  ul {
    margin: 0; padding: 0;
    list-style: none;
    li {
      // margin: 0 0 40px;
      padding: 14px 30px 14px 15px;
      @include type(24px, 1.5);
      font-weight: 600;
      // display: inline-block;
      clear: left;
      float: left;
      &:last-child {
        margin: 0;
      }
      &:nth-child(1) {
        color: #fff;
        background: #0000F7;
      }
      &:nth-child(2) {
        color: #fff;
        background: #00007C;
      }
      &:nth-child(3) {
        color: #fff;
        background: $color-neon-blue;
      }
      &:nth-child(4) {
        color: #fff;
        background: #0000F7;
      }
      &:nth-child(5) {
        color: #fff;
        background: #00007C;
      }
      &:nth-child(6) {
        color: #fff;
        background: $color-neon-blue;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .heading2 {
      @include type(36px, 1.1);
      margin: 0 0 50px;
    }
    ul {
      li {
        padding: 14px 20px 14px 15px;
        @include type(18px, 1.5);
      }
    }
  }
  @include media-breakpoint-down(md) {}
  @include media-breakpoint-down(sm) {
    .heading2 {
      @include type(24px, 1.1);
      margin: 0 0 30px;
    }
    ul {
      li {
        @include type(16px, 1.5);
      }
    }
  }
  @include media-breakpoint-down(xs) {}
}


.work {
  background: $color-lime;
  .heading2 {
    color: #000;
  }
  .heading3 {
    color: #0000F7;
  }
  

  .work-block {
    height: 500px;
    overflow: hidden;
    position: relative;
    margin: 0 0 100px;
    &:last-child {
      margin: 0;
    }
    .container {
      position: relative;
      height: 100%;
    }
    figure {
      position: absolute;
      top: 1px; width: 60%;
      bottom: 1px;
      @include bg-cover();
      // border: 10px solid $color-lime;
      // box-shadow: 0 0 1px 1px rgba(#0000F7, 1);
    }
    .block-content {
      @include absolute-center-vertical();
      position: absolute;
      width: 45%;
      background: #fff;
      border: 2px solid #fff;
      z-index: 1;
      p {
        margin: 0 0 30px;
      }
      .link-btn {
        background: #0000F7;
      }
    }
    .block-content-inner {
      border: 10px solid $color-lime;
      padding: 50px 40px;
    }
    &:nth-child(even) {
      .block-content {
        left: 0;
        // border-left: none;
      }
      figure {
        right: 0;
        // border-right: none;
      }
    }
    &:nth-child(odd) {
      .block-content {
        right: 0;
        left: auto;
      }
      figure {
        left: 0;
      }
    }
  }
  .wp-block-group {
    .wp-block-columns {
      margin-bottom: 100px;
      figure {
        margin: 0;
      }
    }
    &:nth-child(odd) {
      .wp-block-column-content-left {
        figure {
          margin-right: -210px;
        }
      }
    }
    &:last-child {
      .wp-block-columns {
        margin: 0;
      }
    }
  }


  @include media-breakpoint-down(md){
    .work-block {
      height: 360px;
      margin: 0 0 80px;
      .block-content {
        p {
          margin: 0 0 20px;
        }
      }
      .block-content-inner {
        padding: 30px 30px 20px;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .work-block {
      height: 320px;
      margin: 0 0 50px;
      figure {
        width: 60%;
      }
      .block-content {
        width: 80%;
        border-width: 1px;
      }
      .block-content-inner {
        padding: 20px 20px 10px;
        border-width: 6px;
      }
    }
  }
}



.technologies {
  background: $color-neon-blue;
  .heading2 {
    color: #fff;
  }
  .technology-list {
    margin: 60px -15px 0;    
    .items {
      padding: 0;
    }
    .item {
      position: relative;
      display: block;
      width: 100%;
      overflow: hidden;
      
      &__wrapper {
        float: left;
        width: 50%;
        padding: 0 15px;
        margin: 0 0 30px;
        &:nth-child(2n+1) {
          clear: left;
        }
        &:nth-child(3), &:nth-child(4) {
          margin-bottom: 0;
        }
      }
      figure {
        @include bg-cover();
        padding-bottom: 56.25%;
        transition: all .3s linear;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        z-index: 1;
        @include gradient-vertical(rgba(#000, 0), #000);
      }
      &__content {
        position: absolute;
        bottom: 0; left: 0;
        width: 100%;
        padding: 40px 40px;
        z-index: 2;
        
        color: #fff;
      }
      &__title {
        overflow: hidden;
        margin: 0 0 10px;
        color: $color-lime;
        @include type(36px, 1.3);
      }
      &:hover {
        .item__title {
          color: #fff;
        }
        figure {
          transform: scale(1.2);
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .technology-list {
      .item {
        &__content {
          padding: 30px;
        }
        &__title {
          @include type(30px, 1.3);
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .technology-list {
      .item {
        &__content {
          padding: 30px;
        }
        &__title {
          @include type(24px, 1.3);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .technology-list {
      margin: 30px 0 0;
      .item {
        &__wrapper {
          float: none;
          width: 100%;
          &:nth-child(3) {
            margin: 0 0 30px;
          }
        }
        &__title {
          @include type(21px, 1.3);
          margin: 0;
        }
      }
    }
  }
}
