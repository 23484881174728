#site-header {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  padding: 37px 0;
  z-index: 990;
  background: #fff;
  background: #000;
  height: 110px;
  .container {
    // position: relative;
    text-align: center;
  }
  transition: all .3s linear;
  .site-title {
    display: inline-block;
    margin: 0; padding: 0;
    @include type(30px, 36px);
    text-align: center;
    font-weight: 400;
    a {
      color: #fff;
      &:hover {
        color: $color-lime;
      }
    }
  }

  .site-navigation {
    font-weight: 700;
    display: none;
    float: right;
    margin-top: 10px;
    font-family: $headings-font-family;
    ul {
      margin: 0; padding: 0;
      list-style: none;
      text-transform: uppercase;
      @include type(14px, 22px);
      letter-spacing: 0.05em;
      li {        
        display: inline;
        margin-left: 6px;
        a {
          color: #000;
          padding: 10px 20px;
          @include border-radius(4px);
          span {
            text-transform: lowercase;
          }
          &:hover {
            background: rgba(#fff, 1);
            color: #000;
          }
        }
        &.current-menu-item {
          a {
            background: rgba(#fff, 1);
            color: #000;
          }
        }
        &.featured {
          a {
            color: #fff;
            &:hover {
              background: #000;
            }
          }
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    background: rgba(#fff, .96);
    padding: 20px 0;
    .site-title a {
      color: $color-blue;
      &:hover {
        color: $color-neon-blue;
      }
    }
    .menu-toggle {
      border-color: #000;
      &::after {
        background: #000;
      }
      &:hover {
        border-color: $color-neon-blue;
        &::after {
          background: $color-neon-blue;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      max-width: 100%;
      padding: 0 30px;
    }
    .site-navigation {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 20px 0;
    .container {
      max-width: 100%;
    }
    .site-title {
      @include type(24px, 1.2);
    }
  }
  @include media-breakpoint-down(sm) {
    .site-title {
      @include type(21px, 1.4);
    }
  }
}

.mobile-navigation {
  background: $color-lime; // $brand-primary;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1010;
  display: block;
  visibility: hidden;
  transform: translateY(-100%);
  @include opacity(0);
  transition: all .5s ease-in-out;
  overflow: auto;

  &.open {
    visibility: visible;
    display: block;
    transform: translateY(0%);
    @include opacity(1);
    .menu-container {
      ul {
        @include opacity(1);
      }
    }
  }

  .menu-container {
    padding: 150px 0 50px 100px;
    width: 100%;
    min-height: 100vh;
    text-align: left;
    overflow: auto;
    vertical-align: top;
    .contact-form {
      max-width: 460px;
      float: left;
    }
    ul {
      float: left;
      vertical-align: top;
      margin: 0; padding: 0;
      list-style: none;
      width: 460px;
      max-width: calc(100% - 40px);
      @include opacity(0);
      transition: opacity .8s ease-in-out;
    }

    ul#primary-menu {  
      font-size: 2em;
      li {
        font-weight: 600;
        margin: 0 0 30px;
        a {
          color: #001884;
          &:hover {
            color: $color-neon-blue;
          }
        }
        &.current-menu-item {
          font-weight: 800;
        }
      }
    }

    ul#secondary-menu {
      font-size: 1.25em;
      li {
        margin: 0 0 20px;
        font-weight: 600;
        a {
          color: #000 !important;
        }
        &.current-menu-item {
          font-weight: 800;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 80px 0 50px $grid-gutter-width/2;
      .contact-form {
        float: none;
        max-width: 320px;
      }
      ul {
        float: none;
        width: auto;
        max-width: 460px;
      }
      ul#primary-menu {  
        @include type(18px, 1.4);
      }
    }
  }
  
  .menu-close {
    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 1020;
    border: none;
    width: 24px; 
    height: 24px;
    background: none;
    padding: 0;
    svg {
      width: 24px; height: 24px;
      fill: #000;
    }
    @include media-breakpoint-down(sm) {
      left: $grid-gutter-width/2; top: 25px;
    }
  }

}
