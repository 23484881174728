#page {
  padding: 110px 0 0;
  min-height: 100vh;
  margin:0;
  display:flex; 
  flex-direction:column;
}

.page-loader {
  @include overlay(fixed, rgba(#fff, 1), 99999);
  .loader {
    @include absolute-center();
    svg path, svg rect {
      fill: #FF6700;
    }
  }
}

#site-footer{
  margin-top:auto; 
}

#main {
  &.has-sticky-header {
    padding-top: 84px;
    @include media-breakpoint-down(md) {
      padding-top: 67px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 57px;
    }
  }
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }
}

.page-section {
  padding: 120px 0 140px;
  &__alt {
    background-color: $gray-100;
  }
  &__faqs {
    .heading3 {
      color: #462066;
    }
  }
  .heading1 {
    margin: 0 0 30px;
  }

  @include media-breakpoint-down(lg) {
    padding: 80px 0 90px;
  }

  @include media-breakpoint-down(md) {
    padding: 50px 0 60px;
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 0 40px;
  }
}


// Loyout for left aligned within the container
.extream-left {
  padding: 40px;
  background-color: $brand-primary;
  width: calc(100vw - (50vw - 50%));
  margin-left: calc(-50vw + 50%); // - 8px
  padding-left: calc(100vw - (50vw + 50%));
  position: relative;
}
.extream-right {
  padding: 40px;
  background-color: $brand-primary;
  width: calc(100vw - (50vw - 50%));
  // margin-right: calc(-50vw + 50%); // - 8px
  padding-right: calc(100vw - (50vw + 50%));
  position: relative;
  @include media-breakpoint-down(md) {
    width: calc(100vw);
    margin-left: calc(-50vw + 50%); // - 8px
    // margin-right: 0;
    padding: 50px calc(100vw - (50vw + 50%));
  }
}
.extream-left-right {
  background-color: $brand-primary;
  width: calc(100vw);
  margin-left: calc(-50vw + 50%); // - 8px
  padding: 50px calc(100vw - (50vw + 50%));
  position: relative;
}
.extream-left-right-nopad {
  padding: 40px;
  background-color: $brand-primary;
  width: calc(100vw);
  margin-left: calc(-50vw + 50%); // - 8px
  position: relative;
}




/* Boxes */
.hover-box {
  position: relative;
  padding-bottom: 100%;
  
  
  &::before {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    z-index: 0;
    background-repeat: repeat;
    background-size: 6px;
    background-position: bottom right;
    transition: all 0.1s ease;
  }
  &:hover::before {
    top: 20px;
    left: 20px;
    transition: all 0.1s ease;
  }
  .item {
    display:block;
    position: absolute;
    left: 0; top: 0;
    bottom: 0; right: 0;
    z-index: 2;
    padding: 20px 25px;
    color: inherit;
    a {
      color: inherit;
    }
  }
  &__shadow {
    &::before {
      left: 13px;
      top: 13px;
    }
  }
  
  
  &__blue {
    .item {
      background-color: $color-blue;
      color: #fff;
    }
    &::before {
      background-image: url(/img/bg-dots-indigo.png);
    }
  }
  &__lime {
    .item {
      background-color: $color-lime;
    }
    &::before {
      background-image: url(/img/bg-dots-lime.png);
    }
  }
  &__neon-blue {
    .item {
      background-color: $color-neon-blue;
      color: #fff;
    }
    &::before {
      background-image: url(/img/bg-dots-blue.png);
    }
  }
  &__dark-blue {
    .item {
      background-color: #70CDDD;
    }
    &::before {
      // background-image: url(../img/bg-dots-blue.png);
    }
  }
  &__green {
    .item {
      background-color: #77AB07;
    }
    &::before {
      // background-image: url(../img/bg-dots-lime.png);
    }
  }
  &__yellow {
    .item {
      background-color: #FFD464;
    }
    &::before {
      // background-image: url(../img/bg-dots-yellow.png);
    }
  }
  &__pink {
    .item {
      background-color: #FFC4C1;
    }
    &::before {
      // background-image: url(../img/bg-dots-magenta.png);
    }
  }
  &__teal {
    .item {
      background-color: $color-teal;
    }
    &::before {
      // background-image: url(../img/bg-dots-teal.png);
    }
  }
  &__grey {
    .item {
      background: #fff;
      border: 1px solid #E0E0E0;
      color: pink;
    }
    &::before {
      // background-image: url(../img/bg-dots-grey.png);
    }
  }
  &__sm {
    .item {
      padding: 20px;
    }
    &::before {
      background-size: 6px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    padding: 0;
    .item {
      position: relative;
      top: auto; left: auto;
      height: auto;
      padding-bottom: 80px !important;
    }
  }
  
}

.shadow-box {
  background: #efefef;
  position: relative;
  padding: 30px;
  &::before {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    left: 20px; top: 20px;
    z-index: -1;
    background-repeat: repeat;
    background-size: 6px;
    background-position: bottom right;
    transition: all 0.1s ease;
  }
  &__teal {
    background-color: #D0E8EE;
    &::before {
      // background-image: url(../img/bg-dots-blue.png);
    }
  }
}
