/**
 * M E N U   T O G G L E   B U T T O N
 * ----------------------------------------------------------------------------
 */
 .menu-toggle {
  // display: none;
  position: absolute;
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  // left: $grid-gutter-width/2;
  // top: 40px;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  // top: 1px;
  cursor: pointer;
  overflow: hidden;
  opacity: 1;
  z-index: 999;
  text-align: center;
  padding: 0;
  width: 22px; height: 18px;
  text-indent: -999em;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%; 
    left: 0; top: 50%;
    transform: translateY(-50%);
    height: 3px; background: #fff;
  }
  &:hover, &:active {
    border-color: $color-lime;
    &::after {
      background: $color-lime;
    }
  }
  @include media-breakpoint-down(lg) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    left: $grid-gutter-width/2;
  }
}