// @import "link-style/ripplelink";
// @import "link-style/text-link";

// * > :first-child { margin-top: 0 !important; }
// * > :last-child { margin-bottom: 0 !important;}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  /* many screen reader and browser combinations announce broken words as they would appear visually */
  word-wrap: normal !important;
}

/* must have higher specificity than alternative color schemes inline styles */
.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}

.site .skip-link:focus {
  clip: auto;
  height: auto;
  left: 6px;
  top: 7px;
  width: auto;
  z-index: 100000;
}

body {
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}


@mixin style-heading() {
  font-weight: 800;
  // font-family: $headings-font-family;
  letter-spacing: -0.015em;
}
@mixin style-heading1() {
  @include style-heading();
  @include type(48px, 1.14);
  @include media-breakpoint-down(md) {
    @include type(36px, 1.14);
  }
  @include media-breakpoint-down(sm) {
    @include type(24px, 1.14);
  }
}
@mixin style-heading2() {
  @include style-heading();
  color: #000;
  margin-bottom: 30px;
  @include type(56px, 1.14);
  @include media-breakpoint-down(lg) {
    @include type(42px, 1.14);
  }
  @include media-breakpoint-down(md) {
    @include type(30px, 1.14);
  }
  @include media-breakpoint-down(sm) {
    @include type(24px, 1.14);
    margin: 0 0 25px !important;
  }
}
@mixin style-heading3() {
  @include style-heading();
  @include type(36px, 1.25);
  margin: 0 0 15px;
  font-weight: 800;
  @include media-breakpoint-down(md) {
    @include type(24px, 1.14);
  }
  @include media-breakpoint-down(sm) {
    @include type(21px, 1.14);
  }
}
@mixin style-heading4() {
  @include style-heading();
  @include type(30px, 1.25);
  font-weight: 700;
  @include media-breakpoint-down(lg) {
    @include type(24px, 1.3);
  }
  @include media-breakpoint-down(md) {
    @include type(21px, 1.3);
  }
  @include media-breakpoint-down(sm) {
    @include type(18px, 1.3);
  }
}

.heading1 {
  @include style-heading1();
}
.heading2 {
  @include style-heading2();
}
.heading3 {
  @include style-heading3();
}
.heading4 {
  @include style-heading4();
}

.highlighted {
  font-size: 1.125rem;
  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.925rem;
  }
}




.link-btn {
  &__wrapper {
    margin: 40px 0 0 !important;
  }
  display: inline-block;
  padding: 15px 30px;
  text-transform: uppercase;
  @include type(14px);
  letter-spacing: 0.1em;
  font-weight: 800;
  background: #001884;
  color: #fff;
  font-family: $headings-font-family;
  margin: 0;
  @include border-radius(4px);
  transition: all .2s ease-in;
  @include ripplelink();
  border: none;
  @include media-breakpoint-down(sm) {
    @include type(12px);
    padding: 12px 20px;
  }
  &:hover {
    background: #000;
    color: #fff;
  }
  &__sm {
    @include type(11px);
    padding: 10px 15px;
    background: $color-neon-blue;
  }
}


.link-button {
  display: inline-block;
  background: $brand-primary;
  padding: 18px 25px 15px;
  font-family: $font-family-sans-serif;
  letter-spacing: 0.25em;
  @include type(12px, 1);
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  @include border-radius(0);
  box-shadow: none;
}


// .articles-list {
//   margin: 0 -20px;
//   .item {
//     float: left;
//     width: 25%;
//     padding: 0 20px;
//   }
// }


// .widget {
//   margin: 40px 0 0;
//   padding: 10px 0 0 0;
//   border-top: 1px solid $gray-400;
//   position: relative;
//   .widget__title {
//     margin: 0 0 30px;
//   }
// }
// .widget-author {
//   padding: 15px 0 0 120px;
//   min-height: 96px;
//   position: relative;
//   .avatar {
//     position: absolute;
//     left: 0; top: 0;
//     img {
//       @include border-circle();
//       max-width: 96px; height: 96px;
//       border: 5px solid $gray-100;
//     }
//   }
// }




.link-more, .link-back {
  font-family: $headings-font-family;
  text-transform: uppercase;
  @include type(14px, 18px);
  letter-spacing: 0.05em;
  font-weight: 700;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    border: solid $brand-primary;
    padding: 2.5px;
    border-width: 2px 2px 0 0;
    display: inline-block;
  }
  &:hover {
    color: $brand-primary;
  }
}

.link-more {
  padding-right: 14px;
  &::after {
    right: 0;
    top: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.link-back {
  padding-left: 14px;
  &::after {
    left: 0;
    top: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}


.entry-content {
  > :first-child { margin-top: 0 !important; }
  > :last-child { margin-bottom: 0 !important;}
  h4, p, li {
    a:not(.link-btn) {
      // @include link-style();
      // @include l2r-line($color-red);
    }
  }
  p, ul, ol {
    margin: 0 0 25px;
  }
  h2 {
    @include type(48px, 1.25);
    font-weight: 800;
    margin: 0 0 30px;
  }
  h3 {
    @include type(30px, 1.25);
    font-weight: 800;
    margin: 35px 0 25px;
  }
  ul, ol {
    padding-left: 20px;
    li {
      padding-left: 10px;
      margin: 0 0 20px;
      ul {
        padding-left: 15px;
        margin: 20px 0 30px;
        li {
          padding-left: 10px;
        }
      }
    }
    &.compact {
      li {
        margin: 0 0 10px;
        ul {
          margin: 10px 0 20px;
        }
      }
    }
  }

  .list-3c {
    margin: 30px 0;
    padding: 0 0 0 20px;
    li {
      float: left;
      width: calc(100%/3);
      margin: 0 0 10px;
      padding-left: 10px;
    }
    @include media-breakpoint-down(sm) {
      margin: 20px 0;
      li {
        float: none;
        width: 100%;
        margin: 0 0 5px;
      }
    }
  }
  
  .list-2c {
    margin: 0 0 30px;
    padding: 0 0 0 20px;
    li {
      float: left;
      width: calc(100%/2);
      margin: 0 0 5px;
      padding-left: 10px;
    }
    @include media-breakpoint-down(md) {
      margin: 20px 0;
      li {
        float: none;
        width: 100%;
        margin: 0 0 5px;
      }
    }
  }
  
  .list-checkmark {
    padding-left: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 25px;
      i {
        position: absolute;
        left: 0; top: 5px;
        font-size: 14px;
        color: #2092ED;
      }
    }
  }
  

  @include media-breakpoint-down(lg) {
    h2 {
      @include type(36px, 1.14);
    }
    h3 {
      @include type(24px, 1.14);
    }
  }

  @include media-breakpoint-down(md) {
    h2 {
      @include type(30px, 1.14);
    }
    h3 {
      @include type(20px, 1.14);
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      @include type(24px, 1.25);
    }
    h3 {
      @include type(18px, 1.4);
      margin: 0 0 25px;
    }
    ul, ol {
      padding-left: 20px;
      li {
        padding-left: 10px;
        margin: 0 0 20px;
      }
    }
  }
}



.wp-block-latest-posts {
  li {
    margin: 0 20px;
    background: #efefef;
    padding-left: 0 !important;
  }
  &__featured-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  a {
    display: inline-block;
    margin: 20px 30px 0;
    color: #000 !important;
    font-weight: 700;
    @include type(24px, 1.2);
  }
  &__post-excerpt {
    padding: 20px 30px 40px;
  }

}


.heading5 {
  @include type(12px, 16px);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include style-heading();
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}