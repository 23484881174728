#site-footer {
  padding: 60px 0 40px;
  background: #000;
  color: #fff;
  .footer-about {
    h2 {
      margin: 0 0 20px -20px;
      padding-left: 14px;
      border-left: 6px solid $color-yellow;
      @include type(48px, 1.14);
    }
    ul {
      margin: 0; padding: 0;
      list-style: none;
      font-size: 12px;
      li {
        margin: 0 0 5px;
      }
    }
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
    letter-spacing: 0.05em;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
    }
    &::before {
      right: 0;
      background: #555;
    }
    &::after {
      background: #fecf2d;
      width: 60px;
    }
  }

  .footer-navigation {
    ul {
      margin: 0; padding: 0;
      list-style: none;
      li {
        margin: 0 0 20px;
        font-weight: 600;
        a {
          color: #fff;
          &:hover {
            color: $color-lime;
          }
        }
      }
    }
  }

  .contact-info {
    margin: 0 0 30px;
    
    p {
      margin: 0 0 10px;
      position: relative;
      padding-left: 25px;
      i {
        position: absolute;
        left: 0; top: 5px;
        // transform: translateY(-50%);
        color: #fecf2d;
      }
    }
  }

  .social-links {
    margin: 0 0 20px;
    a {
      font-size: 18px;
      display: inline-block;
      background: #333;
      color: #eee;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;
      position: relative;
      i {
        position: absolute;
        left: 50%; top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      &:hover {
        background: #000;
        i {
          color: $color-lime;
        }
      }
    }
  }

  a {
    color: $color-lime;
    &:hover {
      color: $gray-300;
    }
  }
  .bottom-links {
    margin: 50px 0 30px; padding: 0;
    list-style: none;
    li {
      display: inline;
      margin-right: 20px;
      font-size: 0.875em;
    }
  }

  .copyright-text {
    font-size: 12px;
    margin: 0 0 10px;
    
  }

  .credits {
    font-size: 12px;
    span {
      margin: 0 0 10px;
      display: block;
    }
    img {
      max-height: 15px;
    }
  }
  @include media-breakpoint-down(lg) {
    .footer-about {
      h2 {
        @include type(36px, 1.14);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .footer-about {
      margin-bottom: 30px;
      h2 {
        margin: 0 0 20px;
      }
    }
    .footer-contact {
      margin-top: 30px;
    }
  }
  @include media-breakpoint-down(sm) {
    .footer-about {
      margin-bottom: 30px;
      h2 {
        border-width: 4px;
        @include type(24px, 1.14);
      }
    }
    .footer-bottom {
      padding: 20px 0 15px;
      margin-top: 30px;
      font-size: 10px;
    }
  }
}