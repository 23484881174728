.blog-header {
  height: 110px;
  background: #000;
}

.blog-list {
  .blog-item {
    margin: 0 0 $grid-gutter-width;
    &.item-article {
      padding-bottom: 30px;
      &:last-child {
        border: none;
      }
    }
    &__image {
      figure { 
        @include imageBg4x3();
        margin: 0 0 20px;
      }
    }
    &__meta {
      margin: 0;
      h5 {
        @include type(14px, 1.3);
        font-weight: 400;
        color: $gray-700;
      }
    }
    &__excerpt {
      font-size: .925em;
      padding-right: 20px;
    }
    h3 {
      margin: 0 0 10px;
      padding: 0;
      @include type(20px, 1.2);
      font-weight: 700;
      a {
        color: #000;
        &:hover {
          color: #000;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .blog-item {
      h3 {
        @include type(22px, 26px);
      }
    }
  }
}


.blog-sidebar {
  ul { 
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
  }
  aside {

    .widget-title {
      margin: 0 0 30px;
    }
    ul li {
      margin: 0 0 20px;
      &:last-child {
        margin: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    aside {
      @include type(16px, 1.3);
    }
  }
  @include media-breakpoint-down(sm) {
    border-top: 1px solid #000;
    padding: 35px 0 0;
  }
}



.entry-header {
  // text-align: center;
  margin: 0 0 60px;
  .postmeta {
    @include type(14px, 1.5);
    font-weight: 400 !important;
    margin: 0 0 10px;
  }
  .entry-title {
    @include type(48px, 1.1);
  }
  @include media-breakpoint-down(md) {
    margin: 0 0 40px;
    .entry-title {
      @include type(36px, 1.1);
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 0 20px;
    .entry-title {
      @include type(24px, 1.1);
    }
  }
}



/** TOOL FOUDNRY **/
.articles-filter {
  border-top: 1px solid $brand-primary;
  border-bottom: 1px solid $brand-primary;
  padding: 20px 0;
  margin: 40px 0;
  ul {
    margin: 0; padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      text-transform: uppercase;
      @include type(14px);
      margin: 0 5px;
      a {
        padding: 8px 25px;
        @include border-radius(30px);
        color: $brand-primary;
        transition: background-color 0.1s ease;
        &:hover {
          background: $brand-primary;
          color: #fff;
        }
      }
      &.active {
        a {
          background: $brand-primary;
          color: #fff;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 25px 0;
    margin: 0 0 30px;
    ul {
      li {
        display: block;
        @include type(12px);
        font-weight: 600;
        margin: 0 0 25px;
        a {
          padding: 6px 20px;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.articles-list {
  article {
    .item {
      padding: 25px;
      @include media-breakpoint-down(xs) {
        padding-bottom: 80px;
      }
    }
    position: relative;
    margin: 0 0 $grid-gutter-width;
    .post-meta {
      margin: 0 0 20px;
      padding-right: 80px;
      position: relative;
      .posted-on {
        position: absolute;
        top: 0; right: 0;
      }
    }
    .posted-in {
      background: #fff;
      padding: 5px 15px;
      @include border-radius(15px);
      position: absolute;
      bottom: 20px; left: 20px;
      color: $brand-primary;
      transition: all 0.1s ease;
    }
    .read-more-link {
      text-transform: uppercase;
      font-weight: 800;
    }
  }
  .hover-box__lime {
    .posted-in {
      background-color: #ffff46;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .hover-box__neon-blue {
    .posted-in {
      background-color: #28347a;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #000 !important;
      }
    }
  }
  
  .hover-box__blue {
    .posted-in {
      background-color: #fff;
      color: #000;
    }
  }
}




.pagination {
  position: relative;
  text-align: center;
  border-top: 1px solid $brand-primary;
  margin-top: 60px;
  padding-top: 20px;
  margin: 0 0 30px;
  
  small {
    display: block;
    color: #828282;
    margin: 0 0 15px;
  }
  .page-link {
    display: inline-block;
    margin: 0 4px;
    font-weight: 700;
    @include type(14px);
  }
  a, .current {
    border: none;
    width: 24px;
    display: inline-block;
    padding: 4px 0 6px;
  }
  a {
    display: inline-block;
    color: #1798AE;
  }
  .current {
    color: #fff;
    background: #1798AE;
    @include border-circle();
  }
  .next, .prev {
    position: absolute;
    bottom: 0;
    width: auto;
    text-transform: uppercase;
  }
  .next {
    right: 0;
  }
  .prev {
    left: 0;
  }
  
}

.post-navigation {
  border-top: 2px solid #000;
  padding-top: 20px;
  margin: 30px 0;
  @include clearfix;
  a {
    border: none;
  }
  .meta-nav {
    text-transform: uppercase;
    @include type(12px, 1);
    font-weight: 400;
  }
  .post-title {
    font-weight: 600;
    @include type(16px, 1.2);
  }
  
  .nav-previous {
    float: left;
    width: 40%;
  }
  .nav-next {
    text-align: right;
    float: right;
    width: 40%;
  }
}


.post-author {
  @include type(13px, 1.5);
  border: 1px solid #e9ecef;
  @include border-radius(4px);
  padding: 15px 120px 15px 15px;
  color: #454545;
  margin: 0 0 40px;
  min-height: 120px;
  position: relative;
  &__image {
    position: absolute;
    right: 15px; top: 15px;
    margin: 0;
    img {
      border: 1px solid #efefef;
      padding: 3px;
      max-width: 90px !important;
      height: auto !important;
    }
  }
  &__name {
    font-weight: 700;
    @include type(18px, 1.4);
    margin: 0;
  }
  h4 {
    font-size: 12px;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(md) {
    @include type(12px, 1.4);
    h4 {
      font-size: 10px;
    }
    &__name {
      @include type(14px, 1.5);
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 50px 0 40px;
  }
}