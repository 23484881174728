// @import google font
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;0,800;1,300;1,400;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800;900&display=swap');

// define theme variables and overwrite bootstrap library
@import "variables";

@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/utilities";


// import theme styling
@import "theme/theme";