// Genderal
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}
@mixin border-radius($radius: 0) {
  -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}
@mixin border-circle() {
  @include border-radius(100%);
}


// Layout
@mixin custom-make-row($padding: $grid-gutter-width/2) {
  margin-left:-($padding);
  margin-right:-($padding);
  
  > [class*="col-"] {
    padding-left: $padding;
    padding-right: $padding;
  }
}


// Typography
@mixin type($size: 12px, $line-height: 1) {
  font-size: $size;
  line-height: $line-height;
}



// Image
@mixin bg-cover() {
  background-size: cover;
  // -ms-behavior: url(/css/backgroundsize.min.htc);
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
}
@mixin imageBg3x1() {
  @include bg-cover();
  width: 100%;
  padding-top: 33.33333%;
  display: block;
}
@mixin imageBg4x1() {
  @include bg-cover();
  width: 100%;
  padding-top: 25%;
  display: block;
}
@mixin imageBg5x2() {
  @include bg-cover();
  width: 100%;
  padding-top: 40%;
  display: block;
}
@mixin imageBg16x9() {
  @include bg-cover();
  width: 100%;
  padding-top: 56.3636364%;
  display: block;
}
@mixin imageBg5x6() {
  @include bg-cover();
  width: 100%;
  padding-top: 120%;
  display: block;
}
@mixin imageBg4x3() {
  @include bg-cover();
  width: 100%;
  padding-top: 75%;
  display: block;
}
@mixin imageBg1x1() {
  @include bg-cover();
  width: 100%;
  padding-top: 100%;
  display: block;
}



// Buttons
@mixin hvr-bob() {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  &:hover, &:focus { //, &:active
    -webkit-animation-name: hvr-bob-float, hvr-bob;
    animation-name: hvr-bob-float, hvr-bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
  }
}




// Input Field
@mixin placeholder($color: $input-color-placeholder, $font-style: normal) {
  // Firefox
  &::-moz-placeholder {
    font-style: $font-style;
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}
  
  



  // Gradients

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: linear-gradient($deg, $start-color, $end-color);
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}



@mixin section-separator-shadow() {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}


@mixin absolute-center() {
  position: absolute;
  left: 50%; top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@mixin absolute-center-vertical() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin overlay($position: 'absolute', $bgColor: rgba(#000, .75), $zIndex: 9) {
  position: $position;
  background-color: $bgColor;
  top: 0; left: 0;
  bottom: 0; right: 0;
  z-index: $zIndex;
}



/**
* SVG Icons
* ----------------------------------------------------------------------------
*/
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $brand-primary;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $brand-primary;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  @include opacity(.9);
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $brand-primary;
  }
}





// Ripple Link
@mixin ripplelink() {
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index:0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none !important;
  
  &:hover{
      z-index:1000;
      box-shadow:rgba(0, 0, 0, 0.3) 0 5px 16px 2px;
      -webkit-box-shadow:rgba(0, 0, 0, 0.3) 0 5px 16px 2px;
      -moz-box-shadow:rgba(0, 0, 0, 0.4) 0 5px 16px 2px;
      // box-shadow:rgba(0, 0, 0, 0.6) 0 16px 16px 0;
      // -webkit-box-shadow:rgba(0, 0, 0, 0.6) 0 16px 16px 0;
      // -moz-box-shadow:rgba(0, 0, 0, 0.6) 0 16px 16px 0;
  }
  .ink {
      display: block;
      position: absolute;
      background:rgba(255, 255, 255, 0.3);
      border-radius: 100%;
      -webkit-transform:scale(0);
          -moz-transform:scale(0);
          -o-transform:scale(0);
              transform:scale(0);
  }
}

.animate {
  -webkit-animation:ripple 0.65s linear;
   -moz-animation:ripple 0.65s linear;
    -ms-animation:ripple 0.65s linear;
     -o-animation:ripple 0.65s linear;
        animation:ripple 0.65s linear;
}

@-webkit-keyframes ripple {
    100% {opacity: 0; -webkit-transform: scale(2.5);}
}
@-moz-keyframes ripple {
    100% {opacity: 0; -moz-transform: scale(2.5);}
}
@-o-keyframes ripple {
    100% {opacity: 0; -o-transform: scale(2.5);}
}
@keyframes ripple {
    100% {opacity: 0; transform: scale(2.5);}
}


// text links
@mixin link-style($color: #000, $font-weight: 400, $hover-color: #EDEADF) {
  outline: none;
  color: inherit;
  font-weight: $font-weight;
  text-decoration: underline;
  text-decoration-thickness: 0.05em;
  &:hover {
      color: $hover-color;
      background: $brand-primary;
  }
  &::selection, &:active {
      background: $brand-primary;
      color: $color-yellow;
  }
  @include media-breakpoint-up(sm) {
    transition: all .2s ease-in;
  }
}


@mixin link-style-footer($color: #000, $font-weight: 400, $hover-color: #EDEADF) {
  outline: none;
  color: inherit;
  &:hover {
      background-color: #EDEADF;
      color: $brand-primary;
  }
  &::selection, &:active {
      background-color: #EDEADF;
      color: $color-teal;
  }
  @include media-breakpoint-up(sm) {
      transition: all .2s ease-in;
  }
}



@mixin l2r-line($color: $brand-primary) {
  display: inline-block;
  position: relative;
  color: $color;
  outline: none;
  &::selection, &:active {
    background: $brand-primary;
    color: #000;
  }
  @include media-breakpoint-up(sm) {
    transition: all .2s ease-in;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    width: 0;
    bottom: 0;
    background: $color;
    height: 2px;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  &:hover::before {
      width: 100%;
  }
}
