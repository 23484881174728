// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 992px,
  xl: 1180px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 40px !default;

// Layout
// -------------------------
$footer-height : 50px;
$header-height: 80px;
$header-height-sm: 60px;

$enable-shadows: true !default;
$enable-gradients: true !default;


// Brand Colors
// -------------------------
$color-yellow: #FECF2D;
$color-lime : #CCFF00;
$color-teal: #57CCED;
$color-neon-blue: #5444FF;
$color-blue: #0000F7;

$brand-primary: $color-blue;
$brand-success: #60bf60;
$brand-warning: #ff9800;
$brand-danger: #de4b33;
$brand-info: #5bc0dd;


$body-color: #212121;
$color-red: #9D1726; //FF0018;
$font-weight-base: 400;

$link-color: $brand-primary;
$link-hover-color: #000;
$link-hover-decoration: none;


// Typography
// -------------------------
$font-family-serif: Merriweather, Helvetica, "Helvetica Neue", Arial, sans-serif;
$font-family-sans-serif: Poppins, 'Gerogia', sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: 'Inter', $font-family-sans-serif;


$font-size-base:              1.125rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$line-height-base:            1.5 !default;

// Buttons
// -------------------------

$btn-primary-border: darken($brand-primary, 3.2%);
$btn-success-border: darken($brand-success, 3.2%);
$btn-warning-border: darken($brand-warning, 3.2%);
$btn-danger-border: darken($brand-danger, 3.2%);
$btn-info-border: darken($brand-info, 3.2%);

// Forms
// -------------------------

$input-border-radius: 0;
$input-border-focus: #2598f9;

// Form states and alerts
// -------------------------

$state-success-text: #569845;
$state-success-bg: #dbf5d3;
$state-success-border: #aed3a5;

$state-info-text: #3a87ad;
$state-info-bg: #d9edf7;
$state-info-border: #98cce7;

$state-warning-text: #bf9853;
$state-warning-bg: #fdf8e2;
$state-warning-border: #f2daab;

$state-danger-text: #b94a48;
$state-danger-bg: #f2dede;
$state-danger-border: #e0b1b8;


// Navbar
// -------------------------

$navbar-light-color: #252525;
$navbar-light-hover-color: #4da5f4;
$navbar-light-active-color: #4da5f4;

$card-spacer-x: 0.75rem;
